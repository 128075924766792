<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        md="5"
        lg="8"
      >
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          LED
        </h2>
      </b-col>
      <b-col
        md="7"
        lg="4"
      >
        <!-- Search -->
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            type="text"
            placeholder="Cari Tahun Semester"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">
          Daftar Tahun Semester
        </h4>
      </b-card-header>

      <!-- Body -->
      <b-table
        id="table-transition-gjmf"
        ref="ledTable"
        class="m-0"
        responsive="sm"
        :items="leds"
        :fields="fields"
        hover
        caption-top
        show-empty
        primary-key="number"
        :tbody-transition-props="transProps"
      >
        <!-- No Data -->
        <template #empty>
          <p class="mb-0 py-2 text-center">
            Tidak ada data
          </p>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="action-button mr-1"
              :to="{ name: 'op-led-form', params: { id: data.item.id } }"
              size="sm"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusVariantBadge(data.item.status || 'Tidak ada status')">
            <strong>{{ data.item.status ||'Tidak ada status' }}</strong>
          </b-badge>
        </template>
      </b-table>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default {
  data() {
    return {
      transProps: {
        name: 'flip-list',
      },
      isLoading: true,
      fields: [
        { key: 'number', label: '#' },
        { key: 'ts', label: 'Tahun Semester', sortable: true },
        { key: 'user.departments[0].program_studi', label: 'Program Studi', sortable: true },
        { key: 'user.departments[0].lkps_program.jenis_program', label: 'Jenjang', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'action', label: 'Aksi' },
      ],
      leds: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,
    }
  },
  computed: {
    statusVariantBadge() { return any => this.$variantBadge[any] },
  },
  watch: {
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetch(keyword), 300)
      }
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch(keyword = '') {
      this.isLoading = true
      const { id } = getUserData()
      useJwt.http.get('list_led', {
        params: {
          user_id: id,
          page: this.pagination.currentPage - 1,
          size: this.pagination.selectedAmount,
          sort: 'ASC',
          searchTs: keyword,
        },
      })
        .then(response => {
          const number = ((this.pagination.currentPage * this.pagination.selectedAmount) - this.pagination.selectedAmount) + 1
          this.leds = response.data.list_leds
            .map((led, index) => ({
              ...led,
              number: index + 1,
              status: led.status === '' ? 'Tidak ada status' : led.status,
              action: '',
            }))
          this.pagination.totalItems = response.data.total_items
        })
        .catch(err => {
          console.log(err)
          this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' })
        })
        .finally(() => this.isLoading = false)
    },
  },
}
</script>
<style>
  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }
</style>
